import router from "@/router";

export function setUser(state, data) {
  state.user = data;
}
export function setIsInfoLoading(state, data) {
  state.isInfoLoading = data;
}
export function setBackendToken(state, data) {
  state.backendToken = data;
}
export function setIsAuthenticated(state, data) {
  state.isAuthenticated = data;
}
export function killUser({ store }) {
  this.commit("user/setIsAuthenticated", false);
  this.commit("user/setBackendToken", "");
  router.push({ name: "AuthStatus" });
}
export function killUserFull(state) {
  state.user = {};
}
export function setDisabledData(state, [key, data]) {
  state.disabledFields[key] = data;
}
export function setSocialsFilledData(state, data) {
  state.disabledFields["isSocialsFilled"] = data;
}
export async function setProjectPersonalFilled(state, data) {
  state.disabledFields["isProjectPersonalFilled"] = await data;
}
export async function setHSPersonalDataFilled(state, data) {
  state.disabledFields["isHSPersonalDataFilled"] = await data;
}
export function setCurrentMembershipStatus(state, data) {
  state.currentMembershipStatus = data;
}
export function setIsTelegram(state, data) {
  state.isTelegram = data;
}
export function setIsTelegramInfo(state, data) {
  state.isTelegramInfo = data;
}
export function setIsSPO(state, data) {
  state.isSPO = data;
}
export function setIsRT(state, data) {
  state.isRT = data;
}
export function clearDisabledFields(state) {
  Object.keys(state.disabledFields).forEach((item) => {
    state.disabledFields[item] = false;
  });
}
export function setEducationPopupShown(state) {
  state.isEducationPopupShown = false;
}
export function pushToStatementsList(state, response) {
  let obj = state.user.info_statements.data.find((item) => {
    return item.id === response.id;
  });
  obj !== undefined
    ? Object.assign(obj, response)
    : state.user.info_statements.data.push(response);
}

export function setIsProfileMustEdit(state, data) {
  state.setIsProfileMustEdit = data;
}
export function setIsPassportMustEdit(state, data) {
  state.setIsPassportMustEdit = data;
}
export async function setSocialActivity(state, data) {
  state.socialActivity = data;
}
export async function setLaborActivity(state, data) {
  state.laborActivity = data;
}
