export default () => ({
  currentPopup: {
    name: "",
    props: {},
    isShown: false,
  },
  serverAvatar: "",
  dontSavedCrop: undefined,
  trayItems: [],
  storage: {
    systemInfo: {},
    projectsList: [],
  },
  isCookieAgree: false,
  docs: {
    // foreign passport
    document_foreign_passport_main_page: "",
    document_foreign_passport_registration_page: "",
    document_foreign_passport_translate: "",
    document_migration_card_front: "",
    document_migration_card_back: "",
    document_registration_sheet_front: "",
    document_registration_sheet_back: "",
    document_tin: "",
    // native passport
    document_passport_main_page: "",
    document_passport_register_page: "",
    document_passport_6_7_page: "",
    document_passport_old_version_page: "",
    document_inn: "",
    // education
    document_student_billet: "",
    document_student_record_book_main_page: "",
    document_student_record_book_session_pages_one: "",
    document_student_record_book_session_pages_two: "",
    document_student_record_book_session_pages_three: "",
    document_student_record_book_session_pages_four: "",
    document_student_record_book_session_pages_five: "",
    document_reference: "",
    document_higher_education: "",
    document_social_need: "",
    document_certificate_absence_academic_debt: "",
    document_certificate_guardianship_adoption: "",
    document_certificate_family_composition: "",
    document_reference_scholarship_amount: "",
    document_certificate_personal_income_student_last_3_months: "",
    document_consent_parents: "",
    document_reference_income_parent_one: "",
    document_reference_income_parent_two: "",
    //portfolio
    image: "",
  },
  portfolioList: [],
  portfolioListLoaded: false,
  newPortfolioList: [],
  portfolioListLength: 0,
  statementsList: [],
  isMainInfoLoading: false,
  frontVersion: undefined,
  frontVersionChanged: false,
  statementId: "",
  currentCategoryData: {},
  individualNominationValue: "",
  collectiveNominationValue: "",
  granprixNominationValue: "",
  AYindividualNominationValue: "",
  AYcollectiveNominationValue: "",
  AYgranprixNominationValue: "",
  portfolioDoc: "",
  portfolioDocId: "",
  resumeDoc: "",
  resumeDocId: "",
  currentCategoryId: "",
  currentCategoryProject: "",
  currentCategoryVersion: "",
  studentYearCollectiveData: {},
  achievementYearCollectiveData: {},
  isDeleteBtnLoading: false,
  isStorageInfoLoading: false,
  achievementItem: {},
  currentAchievementId: "",
  achievementsList: [],
  achievementsItemForPush: {},
  isOnAchievementsPage: false,
  isProxyServer: "",
  appealsList: [],
  isOldApplication: false,
  HSDocsList: {
    portfolio: { doc: "", id: "", url: false },
    anketa_client: { doc: "", id: "", url: false },
    financial_personal_account_indicating_residential_total_area: {
      doc: "",
      id: "",
      url: false,
    },
    certificate_main_directorate_federal_registration_service_republic_tatarstan_all_family:
      { doc: "", id: "", url: false },
    // certificate_main_need_to_improve_living_conditions: {
    //   doc: "",
    //   id: "",
    //   url: false,
    // },
  },
  firstShiftStatementData: {},
  secondShiftStatementData: {},
  thirdShiftStatementData: {},
});
