<template>
  <div class="Layout-Start">
    <div class="layout-start__wrapper">
      <slot />
    </div>
  </div>
</template>

<script>
import { createRequest } from "@/api/requestBuilder";
import requestConfigs from "@/api/requestConfigs";
import { mapState } from "vuex";

export default {
  name: "LayoutStart",
  computed: {
    ...mapState("user", ["isAuthenticated"]),
  },
  beforeMount() {
    if (JSON.stringify(this.$store.state.storage.systemInfo) === "{}")
      createRequest(requestConfigs.GETSystemInfo).then((result) => {
        this.$store.commit("setStorageData", [result, "systemInfo"]);
      });
  },
};
</script>

<style lang="scss">
.layout-start {
  &__wrapper {
    min-height: 100vh;
    display: flex;
    align-items: center;
    background-color: var(--background--main);
  }
}
</style>
